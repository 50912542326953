import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use a loader to indicate to users that content is loading and is taking
extra time to appear. We recommend showing only one Loader on a page at a
time.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <h3 {...{
      "id": "sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Sizes`}</h3>
    <p>{`There are 5 available sizes for a Loader.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex hideEditor",
        "wrap": "flex",
        "hideEditor": true
      }}>{`<Loader size="small"/>
<Loader size="medium"/>
<Loader size="large"/>
<Loader size="xLarge"/>
<Loader size="xxLarge"/>
`}</code></pre>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Small`}</strong>{`: Use inside components like small Buttons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{` (default): Use the default size inside components like default
Buttons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Large`}</strong>{`: Use this size for bigger items like the video player or podcast
player.`}</li>
      <li parentName="ul"><strong parentName="li">{`xLarge`}</strong>{`: Use this size for bigger items like the video player or podcast
player or a smaller section in page.`}</li>
      <li parentName="ul"><strong parentName="li">{`xxLarge`}</strong>{`: Ue this size where the entire page is loading.`}</li>
    </ul>
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <h3 {...{
      "id": "button-loading-state",
      "style": {
        "position": "relative"
      }
    }}>{`Button loading state`}</h3>
    <p>{`If a loader is triggered by a button, place the spinner in the button, and
disable the button while the loader is visible.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex hideEditor",
        "wrap": "flex",
        "hideEditor": true
      }}>{`<Button loading>I'm loading</Button>
`}</code></pre>
    <h3 {...{
      "id": "one-loader-at-a-time",
      "style": {
        "position": "relative"
      }
    }}>{`One Loader at a time`}</h3>
    <p>{`Try to limit the Loaders per page. If the situation occurs where more than one
part of content is loading, try to use a xLarge or xxLarge size for the whole
section or page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex hideEditor",
        "wrap": "flex",
        "hideEditor": true
      }}>{`<Button loading>I'm loading</Button>
<Button appearance="secondary" loading>I'm loading</Button>
<Button appearance="outline" loading>I'm loading</Button>
<Button appearance="plain" loading>I'm loading</Button>
`}</code></pre>
    <h3 {...{
      "id": "position",
      "style": {
        "position": "relative"
      }
    }}>{`Position`}</h3>
    <p>{`Try to always place the Loader in the center of the content's container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex hideEditor",
        "wrap": "flex",
        "hideEditor": true
      }}>{`<Box
  bgColor="grey10"
  p={9}
  borderWidth={1}
  borderStyle="solid"
  borderRadius={2}
  borderColor="colorGrey30"
>
  <Loader size="medium" />
</Box>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      